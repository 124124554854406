<template>
  <GroupDocuments
    v-if="group && documents"
    :current-group-id="group.id"
    :current-group="group"
    :documents="documents"
    @delete="deleteSelectedDocument($event)"
    @reload="getGroupDocuments"
  />
</template>

<script>
import GroupDocuments from "./GroupDocuments/GroupDocuments.vue";
import {useApplicationContext} from "@/shared/composables/use-application-context";
import {useToastNotification} from "@/shared/composables/use-toast-notifications";
import { useGroupApi } from "@/modules/group/composables/use-group-api";

export default {
  components: { GroupDocuments },
  setup() {
    const { getDocuments, deleteDocument } = useGroupApi();
    const {displaySuccessMessage,displayErrorMessage} = useToastNotification();
    const { forceUpdate} = useApplicationContext();

    return {
      getDocuments,
      deleteDocument,
      displaySuccessMessage,
      displayErrorMessage,
      forceUpdate
    };
  },
  computed: {
    group() {
      return this.$store.state["app-groups"]?.currentGroup;
    },
    documents() {
      return this.$store.state["app-groups"]?.documents;
    },
  },
  methods: {
    getGroupDocuments() {
        this.getDocuments(this.group.id).then((response) => {
            this.$store.commit("app-groups/setDocuments", response);
        });
    },
    deleteSelectedDocument(document) {
        try {
          this.deleteDocument(this.group.id, document.id).then(
            (response) => {
              this.displaySuccessMessage("Document supprimé avec succès");
              this.getGroupDocuments();
              this.forceUpdate();
            }
          );
        } catch (e) {
          this.displayErrorMessage(
            "Une erreur est arrivée en essayant de retrouver les information du group"
          );
        }
    },
  },
};
</script>
